const path = require('path');
const { LOCALES } = require('./src/markets');

const isClient = typeof window !== 'undefined';
const defaultLocale = 'en';

module.exports = {
  defaultNS: 'common',
  i18n: {
    defaultLocale,
    locales: LOCALES,
    localeDetection: false,
  },
  fallbackLng: getFallbackLng,
  use: [
    {
      type: '3rdParty',
      init: (i18next) => {
        i18next.services.formatter.add('formattedNumber', (value) =>
          Intl.NumberFormat(i18next.language).format(value),
        );
      },
    },
  ],
  react: {
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'ul', 'li', 'p'],
  },
  serializeConfig: false,
  ...(!isClient ? { localePath: path.resolve('./public/locales') } : {}),
};

// i18n falls back to default (`en`) when the key isn't in the locale-specific JSONs.
// For cases like `ru-LT`, it should fall back to `ru` to prevent duplicating translations.
// `nonExplicitSupportedLngs: true` helps, but removes `en` as the default, displaying untranslated keys.
// Solution inspired by: https://www.i18next.com/principles/fallback#fallback-to-different-languages
function getFallbackLng(locale) {
  const fallbacks = [getLanguage(defaultLocale)];

  const language = getLanguage(locale);
  if (language !== locale) {
    fallbacks.unshift(language);
  }

  return fallbacks;
}

function getLanguage(locale) {
  return locale.split('-')[0];
}
