import type { MARKETS } from 'markets';

enum WatermarkPosition {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

type MarketId = keyof typeof MARKETS;

type WatermarkImage = { title: string; src: string };

type CountryWatermarkImageMap = Record<MarketId, WatermarkImage>;

export { WatermarkPosition };
export type { MarketId, CountryWatermarkImageMap, WatermarkImage };
