import type { AppProps } from 'next/app';
import { appWithTranslation, type UserConfig } from 'next-i18next';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import { App } from 'components/App';
import '../styles/globals.scss';
import nextI18NextConfig from '../../next-i18next.config';

const NextApp = ({ Component, pageProps }: AppProps) => (
  <App>
    <Component {...pageProps} />
  </App>
);

// FIXME: https://github.com/i18next/next-i18next/issues/2049#issuecomment-1348789516
export default appWithTranslation(NextApp, nextI18NextConfig as UserConfig);
