import { createContext, useContext, useMemo, useState } from 'react';
import type { FilePondFile } from 'filepond';

type PhotoContextValue = {
  photos: FilePondFile[];
  setPhotos: React.Dispatch<React.SetStateAction<FilePondFile[]>>;
};

const PhotoContext = createContext<PhotoContextValue | undefined>(undefined);

const PhotoProvider = ({ ...otherProps }) => {
  const [photos, setPhotos] = useState<FilePondFile[]>([]);

  const value = useMemo(
    () => ({
      photos,
      setPhotos,
    }),
    [photos],
  );

  return <PhotoContext.Provider value={value} {...otherProps} />;
};

const usePhotos = () => {
  const photoContext = useContext(PhotoContext);

  if (photoContext === undefined) {
    throw new Error('usePhotos must be inside a PhotoProvider');
  }

  return photoContext;
};

export { usePhotos, PhotoProvider };
