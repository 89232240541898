import { createContext, useContext, useMemo, useState } from 'react';
import { Step } from 'types/step';

type StepsContextValue = {
  step: Step;
  setStep: React.Dispatch<React.SetStateAction<Step>>;
  resetSteps: () => void;
};

const StepsContext = createContext<StepsContextValue | undefined>(undefined);

const StepsProvider = ({ ...otherProps }) => {
  const [step, setStep] = useState<Step>(Step.Introduction);

  const value = useMemo(
    () => ({
      step,
      setStep,
      resetSteps: () => setStep(Step.Introduction),
    }),
    [step],
  );

  return <StepsContext.Provider value={value} {...otherProps} />;
};

const useSteps = () => {
  const stepsContext = useContext(StepsContext);

  if (stepsContext === undefined) {
    throw new Error('useSteps must be inside a StepsProvider');
  }

  return stepsContext;
};

export { useSteps, StepsProvider };
