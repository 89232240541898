import { createContext, useContext, useMemo, useState } from 'react';
import { IMAGES, DEFAULT_WATERMARK_OFFSET } from 'constants/watermark';
import { type WatermarkImage, type MarketId, WatermarkPosition } from 'types/settings';
import { useMarket } from './useMarket';

type Settings = {
  position: WatermarkPosition;
  watermark: WatermarkImage;
  market: MarketId;
  offsetScale: number;
};

type SettingsContextValue = Settings & {
  setSetting: (setting: Settings) => void;
  resetSettings: () => void;
};

const SettingsContext = createContext<SettingsContextValue | undefined>(undefined);

const SettingsProvider = ({ ...otherProps }) => {
  const { market } = useMarket();

  const defaultSettings = useMemo<Settings>(
    () => ({
      position: WatermarkPosition.TopLeft,
      offsetScale: DEFAULT_WATERMARK_OFFSET,
      watermark: market ? IMAGES[market] : IMAGES.usa,
      market: market || 'usa',
    }),
    [market],
  );

  const [settings, setSettings] = useState<Settings>(defaultSettings);

  const value = useMemo(
    () => ({
      ...settings,
      setSetting: (setting: Settings) => setSettings({ ...settings, ...setting }),
      resetSettings: () => setSettings(defaultSettings),
    }),
    [settings, defaultSettings],
  );

  return <SettingsContext.Provider value={value} {...otherProps} />;
};

const useSettings = () => {
  const settingsContext = useContext(SettingsContext);

  if (settingsContext === undefined) {
    throw new Error('useSettings must be inside a SettingsProvider');
  }

  return settingsContext;
};

export { useSettings, SettingsProvider };
