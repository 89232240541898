const MARKETS = {
  usa: {
    countryCode: 'US',
    locales: ['en', 'en-US'],
  },
  lithuania: {
    countryCode: 'LT',
    locales: ['lt'],
  },
  latvia: {
    countryCode: 'LV',
    locales: ['lv'],
  },
  poland: {
    countryCode: 'PL',
    locales: ['pl'],
  },
  romania: {
    countryCode: 'RO',
    locales: ['ro'],
  },
  hungary: {
    countryCode: 'HU',
    locales: ['hu'],
  },
  france: {
    countryCode: 'FR',
    locales: ['fr'],
  },
  czechia: {
    countryCode: 'CZ',
    locales: ['cs'],
  },
  croatia: {
    countryCode: 'HR',
    locales: ['hr'],
  },
  bulgaria: {
    countryCode: 'BG',
    locales: ['bg'],
  },
  italy: {
    countryCode: 'IT',
    locales: ['it'],
  },
  estonia: {
    countryCode: 'EE',
    locales: ['et'],
  },
};

const LOCALES = Object.values(MARKETS).flatMap((market) => market.locales);

module.exports = { MARKETS, LOCALES };
