import { useRouter } from 'next/router';
import { findKey } from 'lodash';
import { MARKETS } from 'markets';
import type { MarketId } from 'types/settings';

const getMarket = (locale?: string) =>
  locale && (findKey(MARKETS, (market) => market.locales.includes(locale)) as MarketId);

const useMarket = () => {
  const { locale } = useRouter();

  const market = getMarket(locale);

  return { market };
};

export { useMarket };
