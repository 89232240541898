import { useEffect } from 'react';

let fontsLoaded = false;

const useFonts = () => {
  useEffect(() => {
    (async () => {
      if (fontsLoaded) {
        return;
      }

      fontsLoaded = true;

      const WebFont = await import('webfontloader');
      WebFont.load({
        custom: {
          families: ['Roboto'],
          urls: ['https://fonts.googleapis.com/css?family=Roboto:400&display=swap'],
        },
      });
    })();
  }, []);
};

export default useFonts;
