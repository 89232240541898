import { Alert, AlertTitle } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'next-i18next';

const PageError = () => {
  const { t } = useTranslation();

  return (
    <Box p={3}>
      <Alert severity="error">
        <AlertTitle>
          <Trans i18nKey="pageError">
            We’re sorry, but <strong>something’s gone wrong</strong>. Our team has been notified
            about this.
          </Trans>
        </AlertTitle>

        <Typography variant="body1">{t('refreshPage')}</Typography>
      </Alert>
    </Box>
  );
};

export { PageError };
