import { useEffect } from 'react';
import Head from 'next/head';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { ErrorBoundary } from '@sentry/nextjs';
import { DrawerProvider } from 'hooks/useDrawer';
import useFonts from 'hooks/useFonts';
import { SettingsProvider } from 'hooks/useSettings';
import { StepsProvider } from 'hooks/useSteps';
import { PhotoProvider } from 'hooks/usePhotos';
import theme from 'theme';
import { PageError } from 'components/common/PageError';

type AppProps = {
  children?: React.ReactNode;
};

const TITLE = 'carVertical Watermark tool';

const App = ({ children }: AppProps) => {
  useFonts();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <ErrorBoundary fallback={<PageError />}>
      <SettingsProvider>
        <StepsProvider>
          <PhotoProvider>
            <DrawerProvider>
              <Head>
                <title>{TITLE}</title>
                <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width"
                />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon-180x180.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="msapplication-TileColor" content="#2d89ef" />
                <meta name="theme-color" content="#ffffff" />
              </Head>
              <CssBaseline />
              <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
            </DrawerProvider>
          </PhotoProvider>
        </StepsProvider>
      </SettingsProvider>
    </ErrorBoundary>
  );
};

export { App };
