import { createContext, useContext, useMemo, useState } from 'react';

type Settings = {
  available: boolean;
  opened: boolean;
};

type DrawerContextValue = Settings & {
  setSetting: (setting: Partial<Settings>) => void;
  resetSettings: () => void;
};

const DEFAULT_VALUES = {
  available: false,
  opened: false,
};

const DrawerContext = createContext<DrawerContextValue | undefined>(undefined);

const DrawerProvider = ({ ...otherProps }) => {
  const [{ available, opened }, setSettings] = useState<Settings>(DEFAULT_VALUES);

  const value = useMemo(
    () => ({
      available,
      opened,
      setSetting: (setting: Partial<Settings>) =>
        setSettings({ ...{ available, opened }, ...setting }),
      resetSettings: () => setSettings(DEFAULT_VALUES),
    }),
    [available, opened],
  );

  return <DrawerContext.Provider value={value} {...otherProps} />;
};

const useDrawer = () => {
  const drawerContext = useContext(DrawerContext);

  if (drawerContext === undefined) {
    throw new Error('useDrawer must be inside a DrawerProvider');
  }

  return drawerContext;
};

export { useDrawer, DrawerProvider };
