import { keys, reduce } from 'lodash';
import { MARKETS } from 'markets';
import type { CountryWatermarkImageMap, MarketId } from 'types/settings';

const DEFAULT_WATERMARK_OFFSET = 5;
const MAX_WATERMARK_OFFSET = 50;

const IMAGES = reduce<MarketId, CountryWatermarkImageMap>(
  keys(MARKETS) as MarketId[],
  (acc, current) => ({
    ...acc,
    [current]: {
      title: `Watermark (${current.toUpperCase()})`,
      src: `/images/watermarks/watermark-${current}.png`,
    },
  }),
  {} as CountryWatermarkImageMap,
);

export { IMAGES, DEFAULT_WATERMARK_OFFSET, MAX_WATERMARK_OFFSET };
