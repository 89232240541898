import { theme as cvTheme } from '@carvertical/ui';
import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: cvTheme.colorBlue,
      dark: cvTheme.colorDarkBlue,
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#fff',
      },
    },
    MuiButton: {
      root: {
        transition: 'color .01s',
      },
    },
    MuiAppBar: {
      root: {
        zIndex: defaultTheme.zIndex.drawer + 1,
      },
    },
    MuiFab: {
      root: {
        position: 'fixed',
      },
    },
    MuiStepper: {
      root: {
        paddingLeft: 0,
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#fff',
      },
    },
  },
});

export default theme;
